import React, { useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

// SERVICES
import { sendOAuthCode } from '../services/AuthService';

// CONTEXT
import AuthContext from '../context/AuthContext';

const LoginCallback = () => {
    const { setUser } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        const handleOAuthCallback = async () => {
            const searchParams = new URLSearchParams(window.location.search);
            const code = searchParams.get('code');
    
            const isCodeUsed = sessionStorage.getItem('oauth_code_used');
    
            if (code && !isCodeUsed) {
                sessionStorage.setItem('oauth_code_used', 'true');
    
                try {
                    const response = await sendOAuthCode(code);
    
                    if (response) {
                        console.log(response);
                        const token = response.token;
                        const user = response.user;
    
                        localStorage.setItem('token', token);
                        setUser(user);
                        sessionStorage.removeItem('oauth_code_used');
                        navigate('/');
                    }
                } catch (error) {
                    console.error('Erreur lors de la connexion Twitch :', error);
                    alert('Erreur lors de la connexion. Veuillez réessayer.');
                }
            }
        };
    
        handleOAuthCallback();
    }, [navigate, setUser]);
    

    return (
        <>
            <Helmet>
                <title>Connexion en cours...</title>
            </Helmet>
            <div>
                <h1>Connexion en cours...</h1>
            </div>
        </>
    );
};

export default LoginCallback;