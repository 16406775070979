// API
import * as authentification from '../api/authApi';

export const checkAPI = async () => {
    try {
        await authentification.checkAPI();
        return true;
    } catch (error) {
        console.error('Erreur lors de la vérification de l\'API :', error);
        return false;
    }
};

export const getUserData = async () => {
    try {
        const response = await authentification.getUserData();
        return response.data;
    } catch (error) {
        console.error('Erreur lors de la récupération des données utilisateur :', error);
        return null;
    }
};

export const sendOAuthCode = async (code) => {
    try {
        const response = await authentification.sendOAuthCode(code);
        return response.data;
    } catch (error) {
        console.error('Erreur lors de l\'envoi du code OAuth :', error);
        throw error;
    }
};